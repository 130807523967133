@import 'src/styles/variables';

.actions {
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 5;
  display: flex;
  align-items: center;
  gap: 10px;

  & .dsp {
    svg {
      width: 16px;
      height: auto;
      color: #222;
    }
  }
}

.update {
  display: flex;
  cursor: pointer;

  &_status {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background: #149c08;
    border: 1px solid #ffffff;
  }
}

.content {
  border-top: 1px solid #f3f3f3;
  padding: 12px 0 0 0;
}

.not_uploaded {
  padding-top: 16px;
}

.title {
  display: flex;
  margin-bottom: 10px;

  &__icon {
    color: $green;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    padding-top: 4px;

    &:global(.red) {
      color: $red;
    }

    & > div {
      display: flex;
      align-items: center;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #222222;
    max-width: 250px;
  }
}

.info {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #222222;
}

.not_uploaded {
  .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #828282;
    margin-bottom: 20px;
  }
}

.checklist {
  width: 100%;
  display: block;
  padding-left: 20px;
  position: relative;

  & .item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 16px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: calc(100% + 2px);
      width: 16px;
      top: calc(-50% - 36px);
      height: calc(100% + 36px);
      border-left: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
    }

    &:first-child {
      margin-top: 0;

      &:after {
        top: calc(-50% + 20px);
        height: calc(100% - 20px);
      }
    }

    & .icon {
      margin-top: 2px;
      margin-right: 8px;

      svg {
        color: #828282;
      }
    }

    & .text {
      span {
        font-size: 13px;
        line-height: 20px;
        color: $black;
        max-width: calc(100% - 24px);
        word-break: break-word;
      }

      strong {
        font-weight: 700;
        display: block;
        margin-bottom: 4px;
      }

      a {
        color: #025dff;
      }
    }
  }
}
