@import 'src/styles/variables';

.radio {
  &:global(._error) {
    & .header {
      color: $red;
    }

    & .item {
      span:before {
        border-color: $red !important;
      }
    }
  }

  &:global(._notField) {
    pointer-events: none;
    filter: grayscale(1);
  }

  &:global(._isNoEdit) {
    & .list {
      & .item {
        input {
          & + span {
            cursor: default;
          }
        }
      }
    }
  }

  & .header {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #828282;
    margin-bottom: 8px;
  }

  & .list {
    & .item {
      margin-left: 32px;

      &:first-child {
        margin-left: 0;
      }

      input {
        display: none;

        &:checked + span {
          &:before {
            border-color: $blue;
          }

          &:after {
            opacity: 1;
          }
        }

        & + span {
          padding-left: 26px;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          position: relative;
          cursor: pointer;

          &:before {
            position: absolute;
            top: -2px;
            left: 0px;
            width: 18px;
            height: 18px;
            content: '';
            border: 1px solid #cbcbcb;
            border-radius: 50%;
            box-sizing: border-box;
            transition: border 0.1s;
          }

          &:after {
            content: '';
            position: absolute;
            top: 2px;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $blue;
            opacity: 0;
            box-sizing: border-box;
            transition: opacity 0.1s;
          }
        }
      }
    }
  }
}
