@import 'src/styles/variables';

$borderColor: #ebebeb;

.form-group {
  position: relative;

  .maxCount {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: $text-hint;
  }

  & .actions {
    position: absolute;
    top: 0;
    right: 4px;
    padding: 14px;
    background: $white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;

    & > * {
      margin-right: 24px;
      z-index: 10;

      &:last-child {
        margin-right: 0;
      }
    }

    & .increaseFS,
    & .decreaseFS {
      display: none;
    }

    & .tooltip {
      margin-left: 0;

      &:hover {
        & .button {
          background-color: #e2e2e2 !important;
          cursor: pointer;

          svg {
            color: $black !important;
          }
        }
      }

      & .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #f4f5f6;
        transition: background 0.1s;

        svg {
          color: $black;
        }
      }
    }
  }

  & .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 20;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  & .title {
    font-size: 14px;
    line-height: 22px;
    color: #222222;
  }

  & .wrapper {
    border: 1px solid $borderColor;
    position: relative;

    &:global(._error) {
      border: 1px solid $red;
    }
  }

  label {
    color: $text-hint;
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
  }

  .code {
    &:global(.fz10) {
      & > div {
        font-size: 10px;
      }
    }

    &:global(.fz12) {
      & > div {
        font-size: 12px;
      }
    }

    &:global(.fz14) {
      & > div {
        font-size: 14px;
      }
    }

    &:global(.fz16) {
      & > div {
        font-size: 16px;
      }
    }

    &:global(.fz18) {
      & > div {
        font-size: 18px;
      }
    }
  }

  :global {
    .CodeMirror {
      font-family: 'Source Code Pro', monospace !important;
      font-size: 14px;
      line-height: 26px;
      color: #0a0a0a;
    }
    .CodeMirror-gutters {
      border-right: none;
      background: $tr;
    }
    .CodeMirror-linenumbers {
      font-size: 14px;
      line-height: 26px;
      text-align: right;
      color: #828282;
      margin-right: 16px;
    }
    .CodeMirror-vscrollbar {
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: $tr;
      }

      &::-webkit-scrollbar-thumb {
        background: #dddddd;
        border-radius: 15px;
      }
    }
    .CodeMirror-wrap pre * {
      word-break: break-word;
    }
  }

  &_error {
    label {
      color: $red;
    }

    input {
      border: 1px solid $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  &__link {
    position: absolute !important;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 12px;
    text-align: right;

    a {
      color: $blue;
    }
  }

  &:global(._isDisabled) {
    .format,
    .increaseFS,
    .decreaseFS {
      display: none;
    }
  }

  &:global(._isModalView) {
    .increaseFS,
    .decreaseFS {
      display: block;
    }
    .actions {
      top: -24px;
      padding: 24px;
      padding-right: 84px;
    }
  }
}

:global(.isLocked) {
  .actions {
    span {
      color: #fff;
    }
  }
}
