@import 'src/styles/variables';

$full_view_header: 90px;

.placeholder {
  min-height: 64px;
  position: sticky;
  top: 48px;

  &:global(.isSmall) {
    .mockHeader {
      &:global(.isActive) {
        height: $full_view_header;
      }
    }

    .header {
      height: $full_view_header;
    }
  }
  &:global(._isActive) {
    z-index: 12;
  }
  &:global(._isfixed) {
    z-index: 1001;
  }
}

.mockHeader {
  display: none;

  &:global(.isActive) {
    display: block;
    height: 102px;
  }
}

.header {
  background: $white;
  height: 102px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & .status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:global(.isToggleSearch) {
      .title {
        display: none;
      }
    }

    .title {
      font-size: 16px;
      line-height: 18px;
      word-break: break-all;

      span {
        &:global(.copied) {
          &:hover {
            cursor: default;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }

      :global(.tooltipComponentHint) {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .search {
    margin-right: 20px;

    input {
      width: 355px;
      height: 40px;
      background: #f4f4f4;
      border-radius: 54px;
      font-size: 14px;
      line-height: 17px;
      color: $text-hint;
      border: none;
      padding: 0 20px;
    }
  }

  .update {
    &:hover {
      cursor: pointer;
    }
    
    svg {
      vertical-align: middle;
    }
  }

  & .disabled {
    pointer-events: none;
    color: #828282;

    svg {
      color: #828282 !important;
    }

    &:hover {
      background: #fff;
    }
  }
}

.reactsvg {
  display: block;
  height: 24px;

  & > div {
    display: block;
    height: 24px;
  }

  &.black {
    path,
    line {
      stroke: #000000;
    }
  }
}

.plag_img {
  width: 24px;
  height: 24px;
  background-color: $blue;
  opacity: 0.2;
  border-radius: 100%;
}
