@import 'src/styles/variables';

.card {
  & :global(.react-tabs__tab-list) {
    pointer-events: all;
  }

  &.optimization {
    :global(.ErrorText) {
      grid-column: span 2;
      justify-content: flex-end;
    }
    :global(.PriceInput) {
      label {
        width: 150px;
        margin-right: 10px;
      }
      :global(.ErrorText) {
        justify-content: center;
      }
    }
  }

  & .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }

    .contract_link {
      display: flex;
      align-items: center;
      gap: 10px;

      & > div {
        &:first-child {
          flex: 1;
        }
      }
    }
  }

  & .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    &:first-of-type {
      margin-top: 0;
    }

    &.row_nm {
      .card__col {
        margin-right: 0;
      }
    }

    &.moreMarginB {
      margin-bottom: 40px;
    }

    @include laptop {
      flex-wrap: wrap;
    }

    @include phone {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  & .col {
    flex: 1 0 auto;

    &.fixWidth {
      max-width: 170px;
      width: 100%;
      display: flex;
      align-items: center;

      &:global(.error) {
        color: $red;
        svg {
          color: $red;
        }
      }

      @include phone {
        max-width: fit-content;
      }
    }

    .title {
      display: flex;
      align-items: center;
      padding-right: 10px;

      svg {
        flex: none;
        margin-left: 10px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include phone {
      margin-right: 0;
      max-width: 100%;
      flex: 1;
      width: 100%;
      margin-bottom: 10px;

      &.fixWidth {
        width: 100%;
        text-align: center;
        font-weight: 500;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & .additionl {
    display: flex;
    align-items: center;
    flex: 1;

    &__label {
      color: #828282;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 12px;
      letter-spacing: 0.03em;
      display: flex;
      align-items: center;
      max-width: 170px;
      width: 100%;
    }
  }

  h3 {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
  }

  & .label {
    color: #828282;
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
  }

  & .tags {
    position: relative;
    &:global(.disabled) {
      .add,
      .input,
      .remove {
        display: none;
      }
    }
    & .add {
      position: absolute;
      top: 0;
      right: 8px;
      height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 32px;
      z-index: 2;
      cursor: pointer;

      svg {
        color: #828282;
        &:hover {
          color: $blue;
        }
      }
    }
    & .plugin {
      position: relative;
      z-index: 1;

      & .input {
        position: relative;
      }
      & .field {
        background: $tr;
        border: 1px solid #cbcbcb;
        box-sizing: border-box;
        border-radius: 1px;
        width: 100%;
        display: block;
        height: 48px;
        text-align: left;
        padding: 0 42px 0 14px;
        font-size: 14px;
        line-height: 22px;
        color: $black;

        &::placeholder {
          color: #aaaaaa;
        }
      }
      & .selected {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -4px;
      }
      & .tag {
        margin: 4px;
        background: #ebebeb;
        border-radius: 1px;
        padding: 0 16px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: $black;
        max-width: 700px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      & .remove {
        margin-left: 8px;
        cursor: pointer;

        &:hover {
          color: $blue;
        }
      }

      & .suggestions {
        z-index: 1;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        right: 0;
        background: $white;
        box-shadow: 0 6px 60px rgba(0, 0, 0, 0.22);
        border-radius: 1px;
        transition: all 0.1s ease;

        ul {
          li {
            height: 48px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding-left: 14px;
            padding-right: 48px;
            font-size: 14px;
            color: $black;
            background: $tr;
            transition: background 0.1s;
            cursor: pointer;

            &:hover {
              background: rgba(0, 0, 0, 0.05);
            }

            mark {
              background: $tr;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  :global(.revert_block) {
    margin-top: 5px;
  }
}

.cards {
  & > div {
    margin-bottom: 20px;
  }
}

.fakePrices {
  display: flex;
  align-items: center;

  strong {
    color: #828282;
    font-size: 12px;
    display: block;
    text-transform: uppercase;
    line-height: 12px;
    letter-spacing: 0.03em;
    margin-right: 24px;
  }

  span {
    font-size: 14px;
    line-height: 32px;
    color: #222222;
    text-transform: uppercase;
    margin-right: 8px;
  }
}

.fakePricesText {
  padding-left: 8px;
  padding-right: 22px;
  border-bottom: 1px solid #ebebeb;
  height: 32px;
}

.actions {
  padding: 0;
  background: $tr;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.container {
  @include campaignGrid;
}

.lists {
  grid-gap: 18px 24px;
  margin-top: 24px;

  :global(.horizontal) {
    width: 300px;
    flex: none;
  }
}

.shortSelect {
  flex: none !important;
  width: 90px !important;
}

:global(.inputField) {
  align-items: flex-start;
}