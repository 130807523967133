@import 'src/styles/variables';

@mixin focusAndHoverControl {
  box-shadow: none !important;
  border-bottom: 1px solid $blue !important;
}

.new_select {
  &--is-disabled {
  }
  &__control {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    border: 1px solid #ffffff00 !important;
    border-bottom: 1px solid $grey !important;

    &--menu-is-open {
      .new_select__dropdown-indicator {
        color: $blue !important;
      }
    }
    &--is-focused {
      @include focusAndHoverControl;
    }
    &--is-disabled {
      background-color: #f2f2f200 !important;

      .new_select__indicators {
        display: none !important;
      }
    }

    &:hover {
      cursor: pointer !important;
      @include focusAndHoverControl;
    }
  }
  &__value {
    &-container {
      & > div {
        width: 100%;
      }
      &--has-value {
      }
    }
  }
  &__single {
    &-value {
    }
  }
  &__input {
    width: 100%;
    input {
      width: 100% !important;
    }
  }
  &__indicators {
    padding: 5px !important;
  }
  &__indicator {
  }
  &__dropdown {
    &-indicator {
      color: #222222 !important;
      padding: 0 !important;
    }
  }
  &__menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    box-shadow: 0px 7px 50px rgba(0, 0, 0, 0.12) !important;
    &-portal {
      z-index: 100000 !important;
    }
    &-list {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      max-height: 200px !important;
    }
    &-notice {
      &--no-options {
      }
    }
  }
  &__option {
    font-size: 14px !important;
    line-height: 22px !important;
    min-height: 40px !important;
    padding: 9px 12px !important;
    background-color: #fff !important;
    color: #222 !important;
    word-break: break-word !important;
    &:hover {
      background-color: #f6f6f6 !important;
      cursor: pointer;
    }
    &--is-focused {
    }
  }
  &__multi-value {
    border-radius: 12px !important;
    overflow: hidden !important;
    background: rgb(246, 246, 246) !important;
    width: fit-content !important;
    &__label {
      padding: 4px 8px !important;
      font-size: 14px !important;
      line-height: 20px !important;
      white-space: break-spaces !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    &__remove {
      background: #e3ecff !important;
      &:hover {
        svg {
          color: $blue !important;
        }
      }
    }
  }
  &__placeholder {
    color: #b9b9b9 !important;
  }
}

.new_selectWrapper {
  flex: 1;
  width: 100%;
  overflow: hidden;
  &._isVerticalStyle {
    .new_select__control {
      min-height: 48px !important;
      border: 1px solid #ebebeb !important;
    }
    .new_select__control--menu-is-open {
      border: 1px solid $blue !important;
    }
    &._isError {
      .new_select__control {
        border: 1px solid $red !important;
      }
    }
  }
  @for $i from 1 to 200 {
    &.height-#{$i} {
      .new_select__control {
        min-height: #{$i}px !important;
      }
    }
  }
  &._isDisabled {
    cursor: not-allowed;

    .new_select__single-value {
      color: #828282 !important;
    }
  }
  &._isOnlyView {
    .new_select__control {
      pointer-events: none !important;
      min-height: initial !important;
    }
    .new_select__control {
      border: none !important;
    }
    .new_select__value-container {
      padding: 0 !important;

      & > div {
        &:last-child {
          margin: 0 !important;
          padding-bottom: 0 !important;
          padding-top: 0 !important;
          width: 0 !important;
        }
      }
    }
    .new_select__single-value {
      font-size: 14px !important;
      line-height: 22px !important;
      margin: 0 !important;
    }
    .new_select__indicators {
      display: none !important;
    }
  }
  &._isError {
    .new_select__control {
      border-bottom: 1px solid $red !important;
    }
    .ErrorText {
      justify-content: flex-end;
    }
  }
  &._isSave {
    .new_select__control {
      background: #e6fce4 !important;
    }
  }
}
