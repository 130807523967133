@import 'src/styles/variables';

.container {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 374px;
}

.widget {
  p {
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }

  a {
    color: $blue;
    transition: color 0.1s;

    &:hover {
      opacity: 1;
      color: $dark-blue;
    }
  }
}

.card {
  & :global(.react-tabs__tab-list) {
    pointer-events: all;
  }

  &__form {
    margin-top: 10px;
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;

    &:first-of-type {
      margin-top: 0;
    }

    &.card__row_nm {
      .card__col {
        margin-right: 0;
      }
    }

    &.card__row_input-with-select {
      .card__col {
        &:nth-child(1) {
          position: relative;
          right: -1px;
          z-index: 1;
          max-width: calc(100% - 115px);
        }

        &:nth-child(2) {
          max-width: 114px;
        }
      }
    }
  }

  &__col {
    margin-right: 20px;
    flex: 1 0 auto;
    max-width: calc(50% - 10px);

    &:last-child {
      margin-right: 0;
    }
  }
}

.actions {
  padding: 0;
  background: $tr;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;

  button {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.checkboxes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.table {
  position: relative;
}

.loader {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  height: calc(100% - 48px);
  z-index: 999;
  background: rgba(255, 255, 255, 0.3);
  pointer-events: all;
  transition: all 0.1s ease;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &:global(.isShow) {
    opacity: 1;
    visibility: visible;
  }
}

.reports {
  & :global(.switch) {
    display: inline-block;
  }

  h4 {
    margin-bottom: 29px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $black;
  }

  & .list {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 21px 0;

    & > div {
      &:last-child {
        border-color: transparent;
        padding-bottom: 0;
      }
    }

    &:global(._disabled) {
      & .group {
        pointer-events: none;
        & > span {
          color: $text-hint;
          font-weight: 400;
        }
      }

      & .select {
        & > div {
          &:first-child {
            span {
              color: $text-hint;
            }

            svg {
              color: $text-hint;
            }
          }
        }
      }
    }

    & .group {
      padding-bottom: 13px;
      border-bottom: 1px #ebebeb solid;
      display: grid;
      grid-template-columns: 63% auto;
      align-items: center;

      & > span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }

    & .select {
      display: inline-block;

      & > div {
        &:first-child {
          height: initial;
          padding-left: 3px;
          padding-right: 36px;
          border: none;

          & > svg {
            font-size: 24px;
            width: 24px;
            height: 24px;
            top: calc(50% - 12px);
          }
        }

        &:last-child {
          width: 120%;
          left: 50%;
          right: initial;
          transform: translateX(-50%);

          & > div {
            height: 40px;
            padding: 12px;
          }
        }
      }
    }
  }
}

.card {
  padding: 0;

  & .header {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    & > h3 {
      flex: 1 0 auto;
      margin-bottom: 0;
    }

    & .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      button {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  & .tabs {
    margin-top: 28px;

    & .panel {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;

      &:global(.react-tabs__tab-panel--selected) {
        padding-top: 28px;
      }
    }

    & .radio {
      margin-bottom: 24px;
    }

    & .options {
      margin-left: 22px;
      margin-right: 22px;
    }

    & .search {
      margin-left: 22px;
      margin-right: 22px;
      margin-bottom: 8px;
      position: relative;

      button {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0;
        cursor: pointer;

        & > svg {
          font-size: 24px;
          color: #828282;
        }

        &:hover {
          svg {
            color: #222;
          }
        }

        &:disabled {
          &:hover {
            color: #828282;
          }
        }
      }

      input {
        display: block;
        width: 100%;
        height: 40px;
        background: $white;
        border: 1px solid #e2e2e2;
        box-sizing: border-box;
        border-radius: 1px;
        color: $black;
        padding: 0 16px;
        font-size: 14px;
        line-height: 22px;

        &:active,
        &:focus {
          border: 1px solid $blue;

          &:hover {
            border: 1px solid $blue;
          }
        }

        &:hover {
          border: 1px solid #a7a7a7;
        }

        &::placeholder {
          color: #828282;
        }
      }
    }

    & .grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      overflow-y: scroll;
      max-height: 248px;
    }

    & .list {
      margin-left: -22px;
      margin-right: -22px;

      h4 {
        padding: 0 22px;
        margin-bottom: 16px;
      }

      & .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 22px;
        padding-right: 22px;

        &:hover {
          background: #f6f6f6;
        }

        & > div {
          display: block;
          width: 100%;
        }

        label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          min-height: 40px;
          padding: 5px 0;
        }
      }
    }
  }
}

.label {
  color: #828282;
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
  line-height: 12px;
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
}

.config_field {
  display: flex;
  width: 100%;
  :first-child {
    flex-grow: 1;
  }
  :global(.prompt) {
    margin-top: 5px;
    margin-left: 30px;
    flex-grow: 0;
  }
}
