@import 'src/styles/variables';

.collapseCard {
  padding: 0 22px 28px 22px;
  &:global(._isOpen) {
    .arrow {
      transform: rotate(0);
    }
    .body {
      visibility: visible;
      opacity: 1;
      overflow: visible;
      transition: all ease-in-out 0.1s;
    }
  }
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding-top: 28px;
    position: relative;
  }

  .arrow {
    transition: 0.2s;
    transform: rotate(-180deg);
  }
  .body {
    transition: 0.5s;
    overflow: hidden;
    visibility: hidden;
    transition: all ease-in-out 0.1s;
  }

  :global(.CollapseCard_hide) {
    display: none;
  }
}
