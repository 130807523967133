@import 'src/styles/variables';

.card {
  position: relative;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.1s;

  &:hover {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15) !important;

    .header {
      background: #e7e7e7;
    }
  }

  &:global(.hasValues) {
    & .header {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: $blue;
        width: 3px;
        height: 100%;
        transition: all 0.1s ease;
      }
    }
  }

  &:global(.isToggle) {
    & .header {
      background: $white;
      border-bottom-color: #ebebeb;

      &:before {
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
      }

      & .title {
        & .img {
          background-color: #e3ecff;

          &:global(.isSettingCreative) {
            svg {
              path,
              circle,
              line,
              :global(.path) {
                stroke: #000;
              }
            }
          }

          svg {
            path,
            circle,
            line,
            :global(.path) {
              stroke: $blue;
            }
          }
        }

        & .name {
          .arrow {
            transform: rotate(180deg);
          }
        }

        .prompt {
          display: block;
          position: relative;
          margin-left: 21px;
          z-index: 2;
          &__content {
            width: 22px;
            height: 22px;

            &_title {
              font-weight: 500;
              margin-bottom: 6px;
            }

            &_more {
              margin-top: 6px;

              a {
                display: flex;
                align-items: center;
                &:hover {
                  text-decoration: none;
                }
              }

              &_icon {
                margin-left: 5px;
                display: flex;
                align-items: center;
              }
            }
          }

          &__tooltip {
            div {
              &:last-child {
                white-space: pre-wrap;
                bottom: calc(100% + 10px);
              }
            }
          }
        }
      }

      & .helpers {
        z-index: 2;

        &:global(.readOnly) {
          display: block;
        }
      }
    }

    & .content {
      display: block;
      height: max-content;
      opacity: 1;
      transform: translateY(0);

      &:global(.isLoaded) {
        padding: 40px 0;
      }
    }

    & .toggle {
      transform: rotate(180deg);
    }
  }

  &:global(.isActive) {
    z-index: 10;
  }

  &:global(.isEditing) {
    & .content {
      & .list {
        & .item {
          &:hover {
            background: #f6f6f6;
          }

          label {
            cursor: pointer;
          }
        }
      }
    }
  }

  & .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 76px;
    padding: 0 22px 0 20px;
    background: #fff;

    &:global(.isThemeFull) {
      height: 55px;
    }

    & > button {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    & .helpers {
      display: flex;
      align-items: center;
      z-index: 3;

      @include phone {
        button {
          span {
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }

      :global(.isLocked) {
        .edit {
          background: $tr;
          pointer-events: none;

          svg {
            color: #828282;
          }
        }
      }

      & .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        z-index: 2;

        & > * {
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      & > div {
        &:last-child {
          margin-left: 24px;

          @include phone {
            margin-left: 10px;
          }
        }
      }
    }

    & .title {
      display: flex;
      align-items: center;

      .prompt {
        display: none;
      }

      & .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #f6f6f6;

        div {
          display: flex;
          align-items: center;
        }

        &:global(.isSettingCreative) {
          background-color: #e6fce4;
        }
      }

      & .text {
        margin-left: 16px;
        display: block;
      }

      & .name {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        strong {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #222222;
        }

        .arrow {
          margin-left: 4px;
          transition: transform 0.1s;
        }

        :global(.prompt) {
          z-index: 20;
        }
      }

      & .hint {
        font-size: 12px;
        line-height: 18px;
        color: #828282;
      }
    }

    & .toggle {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #828282;
      border-radius: 50%;
      cursor: pointer;
      transition: background, transform 0.1s ease;

      &:hover {
        background: #f6f6f6;
      }
    }
  }

  & .content {
    display: none;
    position: relative;
    background: #fff;
    opacity: 0;
    height: 0;
    transform: translateY(-20px);

    & .settingsToggle {
      height: 40px;
      background: #f6f6f6;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 22px;
      margin-bottom: 14px;
      position: relative;

      &:global(._isShowHint) {
        .settingsToggleHelp {
          display: block;
        }
        :global(.toggle) {
          box-shadow: 0px 0px 12px #0456e9;
        }
      }

      .settingsToggleHelp {
        position: absolute;
        top: calc(100% + 5px);
        left: 10px;
        background: #ffffff;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
        border-radius: 1px;
        width: 447px;
        z-index: 50;
        padding: 16px 48px 16px 56px;
        display: none;

        &::after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0px 10px 10px 10px;
          position: absolute;
          top: -9px;
          left: 20px;
          filter: drop-shadow(0px 15px 10px rgba(0, 0, 0, 0.12));
          background: #fff0;
          z-index: -1;
          border-color: #fff #fff0 #fff #fff0;
        }

        .settingsIcon {
          position: absolute;
          left: 16px;
          top: 16px;
        }

        .title {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 10px;
        }

        .body {
          font-size: 14px;
          line-height: 22px;
        }

        .close {
          position: absolute;
          right: 16px;
          top: 16px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    & .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding: 0 22px 17px;
      border-bottom: 1px solid #ebebeb;

      @include laptop {
        flex-wrap: wrap;
        justify-content: flex-end;
      }

      &:global(.isSearchOpened) {
        justify-content: flex-end;

        .filter,
        .spacer,
        .limits {
          display: none;
          pointer-events: none;
        }
      }

      & .spacer {
        flex: 1 0 auto;
      }

      & .toggleSwitch {
        margin-left: 14px;
      }

      & .filter {
        position: relative;
        opacity: 1;
        pointer-events: all;
      }

      & .date-range-filter {
        flex: none;
        margin-left: 18px;
      }

      & .options_container {
        width: 20px;
        height: 23px;
        margin-left: 18px;
      }

      & .options {
        &:global(.isDisabled) {
          cursor: default;
        }
      }
    }

    & .list {
      position: relative;
      z-index: 10;

      & .item {
        padding: 0 22px 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        transition: all 0.2s ease;

        &:global(.isHeader) {
          border-bottom: 1px solid #ebebeb;

          &:hover {
            background: $tr;
            cursor: default;
          }

          .name,
          .input {
            padding-top: 15px;
            padding-bottom: 15px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: $black;
          }

          .name {
            padding-left: 32px;
            border-right: 1px solid #ebebeb;
            width: 300px;
          }

          .input {
            text-align: center;
          }
        }

        &:global(.isTable) {
          & .name {
            border-right: 1px solid #ebebeb;
            width: 300px;
          }
        }

        & .name {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        & .input {
          width: 68px;
          margin: 0 13px 0 21px;
        }

        & .bid {
          & > input {
            width: 68px;
            margin: 0 13px 0 21px;
            padding: 0 8px;
            background: #fff;
            border: 1px solid #ddd;
            box-sizing: border-box;
            border-radius: 1px;
            height: 28px;
            font-size: 14px;
            color: #0a0a0a;
            transition: border 0.1s;

            &:focus {
              outline: none;
              border: 1px solid #025dff;
            }
          }
        }

        & .spacer {
          flex: 1 0 auto;
        }

        label {
          padding: 9px 0;
          display: flex;
          align-items: center;
          height: 100%;
        }

        h4 {
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          letter-spacing: 0.03px;
          font-weight: 500;
        }
      }
    }
  }
}

.limits {
  cursor: pointer;
  margin-left: 14px;
  opacity: 1;
  pointer-events: all;

  &:global(.isActive) {
    color: #025dff;
  }

  &:global(._isDisabled) {
    cursor: not-allowed;
    svg {
      pointer-events: none;
      color: #828282;
    }

    :global(.tooltipComponent) {
      &:hover {
        :global(.tooltipComponentTitle) {
          svg {
            color: #828282;
          }
        }
      }
    }
    :global(.tooltipComponentHint) {
      display: none;
    }
  }

  svg {
    vertical-align: middle;
  }
}

.download_btn,
.update {
  cursor: pointer;
  margin-left: 14px;
  svg {
    transition: 0.3s;
    vertical-align: middle;
  }
  &:hover {
    svg {
      color: #025dff;
    }
  }
  &:global(._isDisabled) {
    cursor: not-allowed;
    svg {
      pointer-events: none;
      color: #828282;
    }

    :global(.tooltipComponent) {
      &:hover {
        :global(.tooltipComponentTitle) {
          svg {
            color: #828282;
          }
        }
      }
    }
    :global(.tooltipComponentHint) {
      display: none;
    }
  }
}

.info_save {
  color: #025dff;
  display: flex;
  align-items: center;
  margin-right: 60px;
  svg {
    margin-right: 12px;
  }
  span {
    font-size: 12px;
    line-height: 18px;
  }
}

.loader {
  padding: 40px 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty {
  margin: 0 auto;
  padding: 32px 0;
  text-align: center;

  &:global(.isDisabled) {
    pointer-events: none;
    cursor: default;
  }
}

.error {
  color: $red;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-top: 8px;

  svg {
    color: $red;
    margin-right: 6px;
    flex-shrink: 0;
  }
}
