@import 'src/styles/variables';

.card {
  &__title {
    margin-bottom: 16px;
  }

  &__form {
    & > * {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-size: 13px;
      line-height: 20px;
      color: $black;
    }
  }
}

:global(.fields__show_on) {
  color: #149c08;
}

.form_registration {
  &__title {
    margin-bottom: 8px;
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr;
  }

  .fields__raw {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 26px;
    border-top: 1px solid #ebebeb;
    padding: 4px 0;
    min-height: 58px;

    &:last-child {
      border-bottom: 1px solid #ebebeb;
    }

    &:global(.isViewing) {
      height: 58px;
    }
  }

  .fields__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }

  .fields__show {
    align-self: center;
    color: #e2e2e2;
    font-size: 14px;
    padding: 2px 13px;
  }
}

.roles {
  &__title {
    margin-bottom: 16px;
  }

  &__show {
    height: 48px;
  }
}

.rolesOptions {
  height: 40px;
  padding: 5px 11px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: #f6f6f6;
  }

  label {
    text-transform: capitalize;
    margin-bottom: 0;
  }

  &__show {
    background: #f6f6f6;
    border-radius: 12px;
    padding: 5px 9px;
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;
  }

  &:global(.isShow) {
    padding-left: 15px;
    height: 49px;
    cursor: auto;

    &:hover {
      background-color: #f6f6f600;
    }
  }

  &:global(.isEmpty) {
    padding: 0px 0px;
  }
}

.partner {
  margin-bottom: 50px;

  &__title {
    margin-bottom: 8px;
  }
  &__hint {
    margin-top: 17px;
    display: flex;
    align-items: center;
  }
  &__hint_icon {
    margin-right: 10px;
  }

  &__link {
    color: #025dff;
    margin-left: 4px;
  }
}

.partnersOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 14px 11px;

  &:global(.isShow) {
    height: 48px;
    padding-left: 14px;

    &:hover {
      background-color: transparent;
      cursor: auto;
    }
  }


  &__title {
    display: flex;
    align-items: center;
  }

  &__avatar {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #3137bd;
    font-weight: 500;
    font-size: 14px;
    margin-right: 16px;
    z-index: 1;
    position: relative;
  }

  &__text {
    word-break: break-all;
    max-width: 85%;
  }

  &__id {
    justify-self: end;
  }

  span {
    color: #828282;
  }

  &:hover {
    background: #f6f6f6;
    cursor: pointer;
  }
}

.partnersSingleValue {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  width: calc(100% - 8px);

  img {
    display: flex;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }

  span {
    color: #828282;
  }
}
