@import 'src/styles/variables';

.header {
  background: $white;
  padding: 22px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 3;

  @include phone {
    padding: 18px 0;
  }

  .container {
    display: grid;
    grid-template-columns: auto auto;

    @include phone {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        margin-right: 16px;
      }

      & > :last-child {
        margin-right: 0;
      }

      @include phone {
        margin-top: 10px;
      }
    }

    .status {
      .title {
        font-size: 16px;
        line-height: 18px;
        word-break: break-all;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          &:global(.copied) {
            &:hover {
              cursor: default;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }

        :global(.tooltipComponentHint) {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  &:global(.isFixed) {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    z-index: 1001;
    box-shadow: 0 2px 28px rgba(0, 0, 0, 0.15);

    @include phone {
      height: auto;
      padding: 10px 0;
    }

    & .status {
      margin-top: 4px;
    }

    & .title {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
    }
  }

  & .loader {
    background: #e2e2e2;
    height: 34px;
    width: 60%;
  }

  & .status {
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    @include phone {
      padding-right: 0;
    }
  }
}
