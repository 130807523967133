@import 'src/styles/variables';

.input {
  display: none;

  &:checked {
    & + .label {
      &::after {
        background: $blue;
        border: 1px solid $blue;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: center;
      }
    }
  }
}

.label {
  position: relative;
  padding-left: calc(18px + 20px);
  line-height: 20px;
  cursor: pointer;
  overflow-wrap: anywhere;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    border: 1px solid #cbcbcb;
    border-radius: 1px;
    width: 18px;
    height: 18px;
    margin-right: 16px;
    transition: border 0.1s, background 0.1s;
  }
}
