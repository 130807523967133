@import 'src/styles/variables';

.options {
  position: relative;

  &:global(.isOpened) {
    .active {
      svg {
        color: $black !important;
      }
    }
    .list {
      opacity: 1;
      transform: translateY(0px);
      pointer-events: all;
    }
  }

  &:global(.isDisabled) {
    .list {
      .item {
        //pointer-events: none;
        color: #828282;
      }
    }
  }

  & .active {
    cursor: pointer;

    &:hover {
      svg {
        color: $black !important;
      }
    }
  }

  & .list {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    padding: 1px 0px;
    background: $white;
    box-shadow: 0px 6px 60px rgba(0, 0, 0, 0.22);
    border-radius: 1px;
    min-width: 160px;
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
    transition: opacity 0.1s, transform 0.1s;
    z-index: 15;

    & .item {
      display: flex;
      min-height: 32px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      transition: background 0.1s;
      cursor: pointer;
      padding: 0 14px;
      white-space: nowrap;

      &:global(._isDisable) {
        pointer-events: none;
      }

      &:hover {
        background: #f6f6f6;
      }
    }
  }
}
