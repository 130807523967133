@import 'src/styles/variables';

.card {
  margin: 0 auto;
  max-width: 774px;
  width: 100%;
  background: $white;
  display: block;
  pointer-events: all;
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.16);
  border-radius: 1px;
  padding: 28px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 26px;

    & .title {
      flex: 1 0 auto;
      max-width: calc(100% - 80px);

      h1 {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    & .close {
      flex: none;
      display: flex;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #f4f5f6;
      justify-content: center;
      align-items: center;

      svg {
        cursor: pointer;
        color: $black;
      }
    }
  }

  .content {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 22px;
    color: #0a0a0a;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .symbols {
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #828282;
      margin-bottom: 6px;
    }

    .error {
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #ff3636;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .buttons {
      display: flex;

      button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.comments {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
    background-color: #fff;

    &:global(._isActiveSearch) {
      padding-left: 8px;
      width: 92%;

      .comments__header_actions {
        width: 100%;

        & > div {
          display: none;

          &:last-child {
            display: flex;
            margin-left: 0;
          }
        }
      }

      .comments__header_search {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border: 1px solid #025dff;
        height: 30px;
        align-items: center;
        padding: 0 12px 0 0;

        :global(.tooltipComponent) {
          svg {
            width: 20px;
            margin-left: 5px;
          }
          &:hover {
            :global(.tooltipComponentTitle) {
              svg {
                color: currentColor;
              }
            }
            :global(.tooltipComponentHint) {
              display: none;
            }
          }
        }

        &_input {
          display: flex;
          padding: 0 12px;
          border: none;
          font-size: 14px;
          line-height: 22px;
          color: #222;
          width: 100%;
          opacity: 1;
          height: auto;
          margin: auto;
        }

        &_close {
          display: flex;
          cursor: pointer;
          color: #828282;

          &:hover {
            color: #222;
          }
        }
      }
    }

    &_title {
      margin-bottom: 0 !important;

      .collapse {
        display: flex;
        align-items: center;
        margin-right: 8px;
        cursor: pointer;
      }
    }

    &_actions {
      display: flex;
      align-items: center;

      & > div {
        cursor: pointer;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        &:hover {
          path,
          polyline {
            stroke: #025dff;
          }
        }
      }
    }

    &_add_comment {
      & > div {
        display: flex;
      }
    }

    &_update {
      position: relative;
      display: flex;

      &_status {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100%;
        width: 8px;
        height: 8px;
        background: #149c08;
        border: 1px solid #ffffff;
      }
    }

    &_search {
      transition: all 0.1s;

      > div {
        margin-left: 0;
      }

      &_icon {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      &_input {
        opacity: 0;
        height: 0;
        width: 0;
        padding: 0;
        margin: 0;
      }

      &_close {
        display: none;
      }
    }
  }

  &__content {
    padding-right: 2px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &:global(._isFewComment) {
      ~ .comments__content_btn {
        margin-top: 0;
      }
    }

    &_noSearch {
      font-size: 14px;
      line-height: 22px;
      color: #828282;
      padding-left: 16px;
    }

    &_btn {
      padding-right: 22px;
      padding-left: 12px;
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid #f2f2f2;

      &:global(.noBorder) {
        border-top: 1px solid #f2f2f200;
      }

      button {
        padding: 0;
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 10px;
    }
  }
}

.comment {
  font-size: 14px;
  line-height: 22px;
  border: 1px solid #149c0800;
  border-radius: 8px;
  padding: 11px 14px 8px 14px;
  margin-bottom: 4px;
  border-bottom: 1px solid #f2f2f2;

  &:last-of-type {
    border-bottom: 1px solid #149c0800;
  }

  &:global(._isOwn) {
    .comment {
      &__header {
        &_email {
          font-weight: bold;
        }
      }
    }
  }

  &:global(._isEdited) {
    border: 1px solid #149c08;
  }

  &:global(._isDeleted) {
    border: 1px solid #ff3636;
    padding: 5px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__deleted {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #ff3636;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    position: relative;
    height: 16px;
    align-items: center;

    &_date {
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      letter-spacing: 0.03em;
      color: #828282;
      display: flex;
      align-items: center;
      opacity: 1;
      transition: 0.2s;

      svg {
        margin-right: 10px;
      }
    }

    &_email {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #222222;
      width: 55%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      b {
        font-weight: bold;
      }

      &:hover {
        width: 100%;
        color: #fff;

        .comment__header_email_hint {
          display: block;
        }

        & ~ .comment__header_date {
          opacity: 0;
        }
      }

      &_hint {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fff;
        z-index: 5;
        height: 100%;
        color: #000;
      }
    }

    &_saved {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #828282;

      &_icon {
        margin-right: 8px;

        & > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;

    b {
      font-weight: 500;
    }

    &:global(._isFullText) {
      height: auto;
      text-overflow: clip;
      overflow: inherit;
      display: block;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    color: #025dff;
    height: 22px;
    position: relative;
    z-index: 2;
    pointer-events: all;

    &:global(._noExpend) {
      justify-content: flex-end;
    }

    &_expand {
      cursor: pointer;
      display: flex;
      align-items: center;

      &_icon {
        margin-right: 10px;

        &:global(._isCollapse) {
          transform: rotate(180deg);
        }
      }
    }

    &_edit {
      cursor: pointer;
    }
  }
}

@keyframes collapse {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180);
  }
}
