@import 'src/styles/variables';

.creator {
  cursor: pointer;
  background: #f4f5f6;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: $blue;
  }
}
