.isError {
  .multi_select,
  .select_partner {
    &__control {
      border-bottom: 1px solid #ff3636 !important;

      &--menu-is-open {
        border-bottom: 1px solid #025dff !important;
      }
    }
  }
}

.multi_select,
.select_partner {
  &--is-disabled {
    & > * {
      cursor: not-allowed !important;
    }
  }
  &__control {
    border-color: #ffffff !important;
    min-height: 40px !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #ebebeb !important;
    box-shadow: none !important;

    &--is-disabled {
      background-color: #ffffff00 !important;

      & > div {
        &:first-child {
          & > div {
            &:last-child {
              display: none !important;
            }
          }
        }
      }
    }

    &--menu-is-open {
      border-color: #025dff !important;
      .multi_select__indicators,
      .select_partner__indicators {
        .multi_select__indicator,
        .select_partner__indicator {
          &:last-child {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      .multi_select__multi-value__label,
      .select_partner__multi-value__label {
        padding-left: 8px !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      }
      .multi_select__multi-value__remove,
      .select_partner__multi-value__remove {
        padding-right: 8px !important;
      }
    }

    &:hover {
      cursor: pointer !important;
    }
  }
  &__placeholder {
    color: #b9b9b9 !important;
    font-size: 14px;
    line-height: 20px;
  }
  &__indicator-separator {
    display: none;
  }
  &__indicator {
    padding: 0 3px !important;
    align-items: center;
    justify-content: center;

    svg {
      transition: 0.5s;
      color: #222;
      width: 20px;
      height: 20px;
    }
  }
  &__menu {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    &-list {
      padding-bottom: 0px !important;
      padding-top: 0px !important;
      overflow-x: hidden !important;
      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
  &__option {
    display: flex !important;
    align-items: center;

    &--is-focused {
      background: #f4f5f6 !important;
      cursor: pointer !important;
    }
    &--is-selected {
      background: #f4f5f6 !important;
      color: #222222 !important;
    }
  }
  &__input {
    width: 100% !important;
    input {
      width: 100% !important;
    }
  }
  &__value-container {
    & > * {
      width: 100% !important;
    }
  }
  &__multi-value {
    margin: 0 !important;
    border-radius: 8px !important;
    margin: 4px !important;
    width: -moz-fit-content !important;
    width: fit-content !important;

    &--is-disabled {
      background-color: #b6b6b65c !important;
      cursor: not-allowed;

      svg {
        color: inherit !important;
      }
    }

    &__label {
      font-size: 14px !important;
      line-height: 20px;
      color: #222222;
      padding: 4px 8px !important;
      white-space: break-spaces !important;
    }
    &__remove {
      svg {
        color: #025dff;
      }
      &:hover {
        background: none !important;
        color: #222222 !important;
        cursor: pointer;
      }
    }
  }
}

.selectWrapper {
  position: relative;

  &.isNotAllowed {
    cursor: not-allowed;
  }
}

.errorText {
  position: absolute;
  right: 0;
  top: calc(100% - 5px);
}
